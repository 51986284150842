@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
@import url('https://fonts.cdnfonts.com/css/bookman-old-style');
:root{
    --b-100: #000000;
    --b-80: rgba(0,0,0,0.8);
    --b-60: rgba(0,0,0,0.6);
    --blue : #193D88;
  }

 body{
  margin : 0;
  overflow: overlay;
}

a{
  text-decoration: none;
}

.b-100{
    color : var(--b-100);
}
.b-80{
    color : var(--b-80);
}
.b-60{
    color : var(--b-60);
}

.blue-border{
    border-color: var(--blue)
}

.blue-button{
    background-color: var(--blue);
}

.no-border{
    border : none;
}

.no-padding{
  padding: 0;
}
.no-background{
  background-color: transparent ;
}
.hero_title_fs{
    font-size: 20px;
    max-width: 1000px;
}

.hero_title_fs::first-letter{
    font-size: 54px;
}

.section_title_fs{
    font-size: 28px;
}

.text_fs{
    font-size: 14px;
}

.temoignage_fs{
    font-size: 16px
}





@media (min-width: 700px)   {
    .hero_title_fs{
        font-size: 32px;
    }
    
    .hero_title_fsize::first-letter{
        font-size: 100px;
    }
    
    .section_title_fs{
        font-size: 32px;
    }
    
    .text_fs{
        font-size: 18px;
    }
    

    
}


@media (min-width: 1000px)   {
    .hero_title_fs{
        font-size: 38px;
    }
    
    .hero_title_fs::first-letter{
        font-size: 140px;
    }
    
    .section_title_fs{
        font-size: 44px;
    }
    
    .text_fs{
        font-size: 20px;
    }
    

    
}



  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    font-family: sen, sans-serif;
  }



  @for $i from 1 through 20 {
  .gap-#{$i*2}{
    gap : #{$i*2}px
  }
}

@for $i from 1 through 10 {
  .mb-#{$i*2}{
    margin-bottom : #{$i*2}px
  }

  .mt-#{$i*2}{
    margin-top : #{$i*2}px
  }

  .ml-#{$i*2}{
    margin-left : #{$i*2}px
  }

  .mr-#{$i*2}{
    margin-right : #{$i*2}px
  }
}

@for $i from 3 through 10 {
  .mt-#{$i*10}{
    margin-top : #{$i*10}px
  }
  .mb-#{$i*10}{
    margin-bottom : #{$i*10}px
  }
}

@for $i from 1 through 10 {
  .pb-#{$i*2}{
    padding-bottom : #{$i*2}px
  }

  .pt-#{$i*2}{
    padding-top : #{$i*2}px
  }

  .pl-#{$i*2}{
    padding-left : #{$i*2}px
  }

  .pr-#{$i*2}{
    padding-right : #{$i*2}px
  }
}

@for $i from 3 through 10 {
  .pt-#{$i*10}{
    padding-top : #{$i*10}px
  }
  .pb-#{$i*10}{
    padding-bottom : #{$i*10}px
  }
  .pl-#{$i*10}{
    padding-left : #{$i*10}px
  }
  .pr-#{$i*10}{
    padding-right : #{$i*10}px
  }
}


@for $i from 1 through 6 {
  .mw-#{$i*200}{
    max-width : #{$i*200}px
  }
}



.container{
    width: 85%;
    margin:  auto;
    max-width: 1300px;
  }
  .w-100{
    width: 100%;;
  }
  .margin_auto{
    margin : 0 auto;
  }
  
.absolute{
  position : absolute;
}

.relative{
  position : relative 
}

.fixed{
  position : fixed 
}

  .flex{
    display : flex;
  }
  
  .wrap{
    flex-wrap: wrap;
  }
  
  .vertical{
    flex-direction: column;
  }
  
  .justify{
    justify-content: center;
  }
  
  .justify_end{
    justify-content: flex-end;
  }
  
  .justify_start{
    justify-content: flex-start;
  }
  
  .align_items_center{
    align-items: center;
  }
  
  .align_content_center{
    align-content: center;
  }
  
  .text_align_center{
    text-align: center;
  }
  
  .noresize{
    resize: none;
  }
  
  
  .block{
    display: block
  }
  
  .grid{
    display : grid;
  }

  .fw_800{
    font-weight: 800;
  }
  .fw_600{
    font-weight: 600;
  }

  .wf{
    color : white;
  }

  .border-r10{
      border-radius: 10px;
  }

  .sen{
    font-family: sen, sans-serif;
  }

.pointer{
  cursor: pointer;
}
  
html::-webkit-scrollbar  ,  .shoppingCart_Panel::-webkit-scrollbar{
    width:6px;
    
  }
  
  html::-webkit-scrollbar-thumb , .shoppingCart_Panel::-webkit-scrollbar-thumb{
    background-color: rgba(97, 97, 97);
    border-radius: 9px;
  }
  
  html::-webkit-scrollbar-thumb:hover , .shoppingCart_Panel::-webkit-scrollbar-thumb:hover{
    background-color: rgb(148, 148, 148);
  }
  
  html::-webkit-scrollbar-track , .shoppingCart_Panel::-webkit-scrollbar-track{
    background-color: rgba(0, 0, 0, 0);
  }
  
  html::-webkit-scrollbar-track:hover ,  .shoppingCart_panel::-webkit-scrollbar-track:hover{
    background-color: rgba(0, 0, 0, 0);
  }
p {
    white-space: pre-line;
  }

  .overflow_hidden{
    overflow: hidden !important;
  }