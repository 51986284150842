.socialMedia {
  padding : 0;
  margin : 0 0 30px 0;
}

.footpage[data_path='/'], .footpage[data_path='/services']{
  background-color: var(--second-backg);
}

.footpage_menu a, .footpage_menu a:visited{
  text-decoration: none;
  font-size:12px;
  color:var(--fourth-color);
  display: block;
  margin: 12px 0;
  text-shadow:  rgba(0,0,0,0.1) 0 3px 6px;
}

.footpage_menu a:hover{
  scale: 1.02;
}

.footpage_logo img{
  width : 4rem;
  display : block;
  margin : 0 auto 30px;
}

.internlink + .internlink {
  margin-top: 30px
}

@media (min-width:800px)  {
    .footpage_menu{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap:180px;
    }

    .footpage_menu a{
      font-size:16px;
      margin : 16px 0
    }

    .internlink + .internlink {
      margin-top: 0px
    }

    .footpage_logo img{
      width : 5rem;
    }
}