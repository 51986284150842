:root{
  --h-ss:2rem;
  --h-ms:2.6rem;
  --h-ls:3.2rem;

  --bigword-ss: 1.9rem;
  --bigword-mss: 2.7rem;
  --bigword-ms: 4rem;
  --bigword-ls: 5.5rem;


  --t-ss:1.2rem;
  --t-ms:1.4rem;
  --t-ls:1.8rem;

  --white: #fff;
  --blue: #295DB5;
  --gold:#D6B874;
  --fourth-color:#988E8E;
  
  --prim-backg:#E8E3D0;
  --second-backg:#F4F2EE;
  --white-backg:#fff;

}


.blue{
  color:var(--blue)
}


@font-face {
  font-family: 'OCRA';
  src: 
  url('../src/Assets/OCRA.woff') format('woff');
  font-style: normal;
  font-weight: normal;

}

.white{
  color:var(--white)
}

.gold{
  color:var(--gold)
}

.section{
  padding: 2rem ;
  min-height: max(980px, 100vh);
}

.intro_title{
  margin-top:30vh;
  font-size:34px;
  max-width: 400px;
  text-shadow:  rgba(0,0,0,0.1) 0 3px 6px;
  line-height: 40px;
}

.intro_text{
  margin-top: 40px;
  font-size:22px;
  color: black;
  text-shadow:  rgba(0,0,0,0.1) 0 3px 6px;
}

.intro_title strong{
  font-size:38px
}


@media (min-width:450px)  {
  .intro_title{
    font-size:42px;
    max-width: 450px;
    line-height: 46px;
  }
  .intro_title strong{
    font-size:48px;
  }

  .intro_text{
    font-size: 26px;
  }

}

@media (min-width:800px)  {

  .intro_title{
    font-size:60px;
    max-width: 600px;
    line-height: 60px;
  }
  .intro_title strong{
    font-size:65px;
  }

  .intro_text{
    font-size: 26px;
  }


}




@media (min-width:1250px)  {

  .intro_title{
    font-size:85px;
    max-width: 1000px;
    line-height: 100px;
  }
  .intro_title strong{
    font-size:90px;
  }

  


  
}