.service_page{
    background-color: var(--second-backg);
}

.service_page p {
    line-height: 1.55em;
}

.section_introservices{
    background-image: url("../../Assets/services.webp");
    background-size: cover;
    background-position-x: 30%;
}

.services .service{

    min-height: max(900px, 80vh);
}

.services{
    background-color: var(--second-backg);
}

.branding, .dev, .socialmedia{
    position: relative;
}
.service::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    opacity: 0.3;
    pointer-events: none;
}

.branding::before{
    background-image:url("../../Assets/book.webp");
    background-size: 450px;
    background-position: bottom  right 10%;
}

.dev::before{
    background-image:url("../../Assets/keyboard.webp");
    background-size: 450px;
    background-position: center  bottom;
}

.socialmedia::before{
    background-image:url("../../Assets/papillon.webp");
    background-size: 300px;
    background-position: bottom center;
}

.dev .container {
    justify-content: flex-end;
}


@media (min-width:750px)  {


    .branding::before{
        
        background-size: 500px;
        background-position: center  right 10%;
    }
    
    .dev::before{

        background-size: 500px;
        background-position: left 10% bottom;
    }
    
    .socialmedia::before{

        background-size: 400px;
        background-position: center right 10%;
    }
}

@media (min-width:1200px)  {


    .branding::before{
        

        background-position: center  right 10%;
    }
    
    .dev::before{


        background-position: left 10% bottom 30%;
    }
    
    .socialmedia::before{

        background-position: center right 20%;
    }

}

@media (min-width:1250px)  {

    .service::before{
        opacity : 0.8
    }

}
