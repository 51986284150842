.portofolio{
    background-color: white;
}

.portofolio .slide_wrapper, .portofolio .slider{
    height: max(800px, 100vh);
}

.portofolio .slide_wrapper{
    align-items: flex-start;
}

.portofolio .slide_wrapper img{
    z-index: 100;
}

.portofolio .rayzon img{
    
    width: min(300px,70%);
    padding-top:40vh;
}

.portofolio .CDR img{
    width: min(250px,50%);
    padding-top:40vh;
}



@keyframes visible {
    0%   {opacity: 0;}

    100%   {opacity: 1 }
}

@keyframes hidden {
    
    0%   {opacity: 1}
    100%   {opacity: 0;}
}
.slide[data-load="false"]{
   visibility :hidden;
}
.slide[data-load="true"]{
    visibility :visible;
 }
.slide[data-active="true"]{
    
    animation : visible 0.8s ease-in-out ;
    animation-fill-mode: forwards;
    
}
.slide[data-active="false"]{
    animation : hidden 0.8s ease-in-out ;
    animation-fill-mode: forwards;
}


.portofolio .slide_background{
    top : 0;
    left: 0 ;
    right: 0 ;
    bottom : 0;
    height: max(800px, 100vh);
    
}

.portofolio .slide{

    top:0;
    left: 0;
    right : 0;

}

.portofolio .slide .rayzon{
    background-image: url('../../Assets/Ramy_final.webp');

    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: black;
    background-size: 600px;
    filter:blur(30px)
}

.portofolio .slide .CDR{
    background-image: url('../../Assets/CDR.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    filter:blur(15px)
    

}

.navbar_filter{
    top: 0;
    left : 0;
    right : 0;
    min-height: 110px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    opacity: 0.7;
}

.slider_nav{
    bottom : 40px;
    left: 50%;
    transform: translateX(-50%);
    gap : 12px;
    z-index: 100;
}

.slider_nav button{
    border-radius: 100%;
    height : 10px;
    cursor: pointer;
    
}

.slider_nav button[data-active="true"]{
    background-color: #548beb;
    box-shadow: 0 0 8px #5d85cc;
    border : solid 0.2px #5d85cc
}

.slider_nav button[data-active="false"]{
    border : none;

}

.slider_nav button:nth-child(1)[data-active="false"]{
    background-color: #cfcfcf; 

}
.slider_nav button:nth-child(2)[data-active="true"]{
    background-color: #cca010; 
    box-shadow: 0 0 8px #d4ae31;
    border : solid 0.2px #dbba4b
}

@media (min-width:500px){
    .portofolio .slide .rayzon{
        background-position-y: 100px;
        background-size: 800px;
    }
}

@media (min-width:750px){
    .portofolio .slide .rayzon{
        background-position-y: -50px;
        background-size: 1150px;
    }
}

@media (min-width: 1200px)   {
    .portofolio .slide_background{
        height: max(800px, 120vh);
    }
    .navbar_filter{
        min-height: 200px;
    }
    .portofolio .slide .rayzon{
        background-position-y: -500px;
        background-size: 2400px;
    }

    .portofolio .rayzon img{
        width: 650px;
    }
    .portofolio .CDR img{
        width: 350px;
        padding-top:35vh;
    }
}



.top_page_button{
    margin : 110px auto;
    cursor: pointer;    
}
.top_page_button img{
    width: 41px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); 
}

.top_page_button:hover img{
    transform: scale(1.1);
    filter: drop-shadow(6px 5px 3px rgb(0 0 0 / 0.3)); 
}