
.intro , .keywords, .logo_work{

    padding : 80px 30px;
    min-height: max(880px, 100vh);
}




/* ***************************** SECTION INTRO ***************************** */
/* ***************************** SECTION INTRO ***************************** */
/* ***************************** SECTION INTRO ***************************** */

.rayzon_pres, .CDR_pres{
    z-index: 500;
    max-width: 1400px;

}

.CDR_pres{
    color : black;
}

.rayzon_pres .intro, .logo_work{
    color : white;
    background-color: black;
}

.CDR_pres .keywords{
    background-color: #E4E4E4;
}

.CDR_pres .intro,.CDR_pres .logo {
    
    background-color: white;
}





.intro_container {
    max-width: 840px;
}

.intro_container img{
    width: 100%;
}
.intro h1{
    margin: 0 auto;
    font-size: 32px;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 5px;
}

.intro h3{
    margin: 0 auto 30px;
    font-size: 16px;
}

.intro_desc {
    margin : 10px auto;
}

.intro_desc p{
   font-size: 12px;
   line-height: 24px;
}


@media(min-width:  750px){
    .intro h1{
        font-size: 36px;
        margin : 0
    }
    
    .intro h3{
        font-size: 20px;
        margin : 0 0 30px;
    }

    .intro_desc{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap : 40px;

    }
    .intro_desc p{
       font-size: 16px;
       line-height: 26px;
    }
}

    @media(min-width:  1000px){
        .intro h1{
            font-size: 60px;
            margin : 0
        }
        
        .intro h3{
            font-size: 24px;
            margin : 0 0 30px;
        }
    
        .intro_desc p{
           font-size: 18px;
           line-height: 28px;
        }
}


/* ------------------------------------------------------------------------------*/



/* ***************************** SECTION KEYWORDS ***************************** */
/* ***************************** SECTION KEYWORDS ***************************** */
/* ***************************** SECTION KEYWORDS ***************************** */


.keywords{
    padding : 120px 20px ;
}

.keywords_container{
    max-width: 500px;
}
.keywords_container h2{
    margin-bottom: 10px;
    font-size: 32px;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 5px;
}

.identity{
    margin-bottom: 150px;
}

.identity_work{
    margin-bottom: 40px;
    
    
}

.identity_work p{
    font-size: 14px;
    line-height: 24px;
}
.list_keywords{
    border-top: solid 4px black;
    border-left: solid 4px black; 
    min-height: 450px;
}

.list_keywords li{
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
}

.list_keywords ul{
    padding-left: 20px;
    margin : 0;
}


/******FONT********/

.font h3{
    font-size: 20px;
}
.font h4{
    font-size: 18px;
    
}

.font_show{
    border-top: solid 4px black;
    max-width: 550px;
    font-size: 16px;
    line-height: 24px;
}

.font_show h5{
    font-size: 18px;
    line-height: 24px;
}



.montserrat{
    font-family: "Montserrat", sans-serif;
}

.permanent_marker{
    font-family: 'Permanent Marker', cursive;
}

.bookman{
    font-family: 'Bookman Old Style', sans-serif;
}

.sen{
    font-family: Sen, sans-serif;
}


.font_desc{
    padding : 20px 15px 15px;
    font-size: 16px;
    line-height: 24px;
    clip-path: polygon(0 0, 100% 0, 100% 69%, 86% 100%, 0 100%, 0% 50%);
    border: solid 1px black;
}


.rayzon_pres .keywords_background{
    background-image: url("../../Assets/controler.webp");
    background-position-y: 35%;
    background-position-x: 170px;
    background-size: 460px;
}

.CDR_pres .keywords_background{
    background-image: url("../../Assets/pen.webp");
    background-position-y: 27%;
    background-position-x: 280px;
    background-size: 70px;
}

.keywords_background{
    width: 99vw;
    bottom : 0;
    top :  0;
    background-repeat: no-repeat;
    pointer-events: none;


}

@media (min-width : 650px){
    .keywords_container{
        max-width: 700px;
    }

    .identity_work {
        padding-right: 40px;
  
    }
    .identity_work p{
        font-size: 18px;
        line-height: 24px;

  
    }
    .identity{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .keywords_container h2{
        margin-bottom: 15px;
        font-size: 36px;

    }
    .identity_work{
        border-top: solid 4px black;
        flex-grow: 1;
        max-width: 500px;
    }
    .list_keywords{
        border-top: none;
        border-left: solid 4px black;
    }

    .list_keywords li{
        font-size: 26px;
    }

    /******FONT********/

    .font h3{
        font-size: 24px;
    }
    .font h4{
        font-size: 22px; 
    }
    .font_show{
        font-size: 20px;
    }
    .font_show h5{
        font-size: 18px;
    }


    .rayzon_pres .keywords_background{
        background-position-y: 35%;
        background-position-x: 20px;
    }
    .CDR_pres .keywords_background{
        background-position-y: 35%;
        background-position-x: 40%;
        background-size: 70px;
    }

}

@media (min-width : 1000px){
    .keywords_container h2{
        margin-bottom: 15px;
        font-size: 46px;
    }
    .identity_work p{
        font-size: 20px;
        line-height: 26px;
    }
    .list_keywords li{
        font-size: 30px;
    }

/******FONT********/
    .font h3{
        font-size: 28px;
    }
    .font h4{
        font-size: 24px;
        
    }
    
    .font_show{
        font-size: 20px;
        line-height: 26px;
    }

    .font_desc{
        font-size: 18px;   
    }
    .font_show h5{
        font-size: 22px;
        line-height: 26px;
    }

    .rayzon_pres .keywords_background{

        background-position-y: 30%;
        background-position-x: 30%;
        background-size: 600px;
    }
}


/* ------------------------------------------------------------------------------*/



/* ***************************** SECTION LOGO ***************************** */
/* ***************************** SECTION LOGO ***************************** */
/* ***************************** SECTION LOGO ***************************** */

.logo_work{
    padding : 120px 20px;
}
.logo_work_container {
    max-width: 840px;
}

.logo_work_container > img{
    max-width: 650px;
    margin : 120px auto 120px;
}

.rayzon_pres .logo_work_container > img{
    width: 85%;
}

.CDR_pres .logo_work_container > img{
    width: 65%;
}

video{
    z-index: 600;
    margin: 0 auto;
    cursor: pointer;
}

.logo_work_container > div{
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    max-width: 400px;
}
.logo_work_container div p {
    margin : 0;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
}

.videoctrl{
    cursor: pointer;
    top : 0;
    bottom : 0;
    left: 0 ;
    right : 0;
    align-items: flex-end;
    display: none;
}

.videoctrl img{
    opacity: 0.3;
    width: 50px ;
}

.videoctrl:hover img{
    opacity: 1;
    content: url("../../Assets/soundicon.webp");

}


@media (min-width: 650px ){
    .logo_work_container > div{
        justify-content: center;
        flex-direction: row;
        max-width: 840px;
    }
    .logo_work_container div p {
        max-width: 400px;
        font-size: 16px;
        line-height: 26px;
    }

    .videoctrl{
        display: flex;
    }
}

@media (hover: none) and (pointer: coarse) {
    .videoctrl{
        display: none;
    }
}



@media (min-width: 1000px ){

    .logo_work_container div p {
        font-size: 18px;
    }
}
/* ------------------------------------------------------------------------------*/


