.navbar{
    justify-content: flex-start; 
    position : absolute;
    top: 0 ;
    min-height: 110px;
    z-index: 110;
    
}

.logo{
    flex-grow: 1;
    width: 100%;
    

}
.logo img{
    width:4rem; 
    cursor : pointer;
}


.navbar nav{
    display: none;
    padding : 50px 0 ;
}

.navbar nav ul li{ 
    text-shadow:  0px 3px 6px rgba(0,0,0,0.16);
    
}
ul{
    list-style: none;
    
}

.navbar a {
    color : black;
} 

.navbar a:hover {
    transform: scale(1.05);
}

.burger {
    cursor: pointer;
    flex : 1 1 0;
    align-items: flex-end;
    display: flex;
}
.burger_line {
    margin-top: 6px;
    height: 2px;
    width: 25px;
    background: #000;
 }
 .language  {
    display: none;
 }


 .overlay{
    top : 0;
    bottom : 0;
    background-color : rgba(0,0,0,0.5);
    cursor : pointer;
    left : 0;
    transition : 0.1s ease-in;
    transform-origin: left;

}
.bandeau[isopen="false"] .overlay{
    right:100vw;
    opacity: 0;
}
.bandeau[isopen="true"] .overlay{
    right:0;
    opacity: 1;
}

.sidebar{
    position: relative;
}

.sidebar > ul{
    margin : 0;
    align-items: flex-start;
    padding : 20px 0 0 20px;;
    top : 0;
    bottom : 0;
    left: 0 ;
    width:80%;
    position :fixed;
    background-color : white;
    z-index : 2;
    transition : 0.2s ease-in;
    transform-origin: top left;
    -webkit-box-shadow: 10px 10px 33px -3px rgba(0,0,0,0.38);
-moz-box-shadow: 10px 10px 33px -3px rgba(0,0,0,0.38);
box-shadow: 10px 10px 33px -3px rgba(0,0,0,0.38);
    
}

.bandeau[isopen="false"] .sidebar > ul {
    max-width: 0;
    transform : scale(0,1);
    opacity : 0;
}


.bandeau[isopen="true"] .sidebar > ul {
    max-width: 80%;
    opacity : 1;
    
}

.lang img{
    width: 28px !important;
    margin : 0 !important;
}
.lang[aria-disabled="true"]{
    cursor: default;
    opacity: 0.5;
 }

.sidebar li{
    margin : 30px 0 0;
    text-align: left;
    font-weight: 600;
    
}

.sidebar img{
    width:80px;
    transform-origin: left top;
    margin-bottom : 20px
    

}

.close{
    position : fixed;
    border : solid 1px rgba(0,0,0,0.3);
    padding : 5px 10px;
    cursor : pointer;
    color : black;
    font-weight: 600;
    top : 30px;
    transition : 0.2s ease-in;
    z-index: 5;
    font-size: 20px;
}
.bandeau[isopen="false"] .close{
    left : 0vw; 
    opacity: 0;
    pointer-events: none;
}

.bandeau[isopen="true"] .close{
    left : 65vw;
    opacity: 1;
    
}

.close:hover{
    transform: scale(1.05)

}
 /* steps of 12 are optional, could also be 10, 13 etc */



 @media (min-width: 750px)   {

    .language {
        display: block;
     }
    .language img{
        width: 32px;
     }
     .language button[aria-disabled="true"]{
        cursor: default;
        opacity: 0.5;
     }
    .bandeau {
        display: none;
        
    }

    .burger{
        display : none;
    }


    .navbar nav{
        display: block;
    }

    .navbar ul{
        justify-content:center;
        margin: 0 auto;
        max-width: 600px;
        padding : 0;
        gap : 20px;
    } 

    .navbar ul li{
        font-size: 18px;
        text-align: center;
        margin : 0 25px;

    } 

}

@media (min-width: 1000px)   {
    .navbar{
        min-height: 200px;
    }

    .logo img{
        width : 5rem;
        margin-left: 30px;
    }


}