.homepage{
  font-family: sen, sans-serif;
  background-color: var(--second-backg);
}
*, *::before, *::after {
  box-sizing: border-box;
}

body{
  margin:0;
  font-family: Sen, sans serif;
}


.container{
  width:85%;
  margin:0 auto;
}

.section1{
  background-color: var(--prim-backg);
  margin:0;
  background-image: url("../../Assets/Banner2.webp");
  background-position-x: 85%;
  background-size: cover;
}


.ylio{
  width:4rem;
  margin-left:7.5%;
}

.section2{
  background-color: var(--second-backg);
  margin:0;
  position:relative;
  background-image: url("../../Assets/cofeeTime.webp");
  background-position:bottom  ;
  background-repeat: no-repeat;
  background-position-y:90% ;

  padding: 2rem ;
  min-height:980px;
}



/* about us section ----------------- */
.aboutus{
  background-image: url("../../Assets/aboutus mobile.webp");
  background-size: cover;
}

.aboutus h2{
  font-size: 28px;
  margin: 100px 0 80px 
}

.aboutus p{
  font-size: 14px;
  line-height: 1.55em;
}

/* services section ----------------- */

.services{
  background-color:#F6F6F6 ;
}




/* form / end section ----------------- */
.flex-ls{
  margin-top:3rem;
}

.form_desc{
  margin-top:2rem;
}
.form_title, .form_text{
  margin:0;
}

.form_title{
  margin:0rem 0 2rem 0 ;
  color:var(--gold);
  font-size:var(--h-ss);
  text-shadow: 2px 2px 8px rgba(0,0,0,0.1);
}

.form_text{
  margin:0 0 4rem 0 ;
  color:var(--gold);
  font-size:var(--t-ss);
}






@media (min-width:450px)  {


/* about us section ----------------- */
  .aboutus h2{
    font-size: 34px;
  }
  .aboutus p{
    font-size: 18px;
  }
}

@media (min-width:800px)  {




/* about us section ----------------- */
  .aboutus h2{
    font-size: 38px;
    max-width: 600px;
  }
  .aboutus p{
    font-size: 22px;
    max-width: 590px;
  }

/* form / end section ----------------- */
  .flex-ls{
    display: flex;
    justify-content: space-between;
  }
  .form_desc{
    width:75%;
    max-width:550px;
    margin-right : 50px;
  }
  .form{
    max-width:850px;
  }

  .section2{
    background-position-y:85% ;
    background-position-x:10% ;
    background-size: 450px;
  }

  .row + .row{
    margin-left:4rem;
  }


  .form_title{
    font-size:var(--h-ms);
  }
  .form_text{
    font-size:var(--t-ms);
  }
}


@media (min-width:950px)  {
  .card_wrapper{
    display : grid;
    justify-content: center;
    align-items: center;
    gap : 20px;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: 420px;
    max-width: 1400px;
  }
}


@media (min-width:1250px)  {

  .card_wrapper{
    gap : 50px;
    
  }
  

  /* about us section ----------------- */
  .aboutus{
    background-image: url("../../Assets/aboutus desktop.webp");
  }
  .aboutus h2{
    font-size: 52px;
    max-width: 1000px;
  }
  .aboutus p{
    font-size: 26px;
    max-width:750px;
  }

/* form / end section ----------------- */
  .form_title{
    font-size:var(--h-ls);
  }
  .form_text{
    font-size:var(--t-ls);
  }
  .section2{

    background-position-y:90% ;
    background-position-x:18% ;
    background-size: 550px;
    min-height: 1100px;
    
  }


  .flex-ls{
    margin-top:5rem;
  }


  
}


@media (min-width:1450px)  {
  .card_wrapper{
    gap : 80px;
  }

  .section2{
    background-position-x:27% ;
  }
}