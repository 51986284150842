
.card{
    margin : 90px 0 ;
    position : relative;
    padding : 70px 20px 40px 50px;
    background-color: white;
    height : 100%;
    width: 100%;
}

.card_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    left : 50%;
    top : 0;
    transform : translate(-50%, -50%);
    width : 100px;
    height: 100px;
    border-radius:100%;
    -webkit-box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.1); 
    box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.1);
}
.card_icon img{
    width: 70px;

}
.card_title{
    font-weight: 800;
    font-size: 26px;
    margin-bottom: 25px;
    color : black;
}

.card_desc{
    font-size: 14px;
    color : black;
    margin-bottom: 80px;
    line-height: 24px;
}

.card_button{
    background-color: black;
    border: none;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
    max-width: 240px;
    width: 100%;
    padding: 15px 0;
    color : white;
    font-family: Sen, sans-serif;
    cursor: pointer;
    letter-spacing: 2px;
}



@media (min-width:450px)  {
    .card_icon{
        left : 150px;
    }
  }

  @media (min-width:950px)  {
    .card_icon{
        left : 50%;
    }

    .card_desc{
        margin-bottom: 0px;
        font-size: 16px;
    }

    .card_button{
        position : absolute;
        left : 50%;
        bottom : 30px;
        transform: translateX(-50%)
    
    }
    .card{
        padding : 70px 10px 40px 20px;
    }
  }


  @media (min-width:1250px)  {
    .card{
        padding : 70px 30px 40px 20px;
    }
  }