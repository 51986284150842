.copyright{
    bottom : 10px;
    text-align: center;
    color : black;
    font-size: 8px;
}


@media (min-width : 650px){
    .copyright{
        font-size: 10px;
    }
}

